// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mat-custom: (
	50: #01518a,
	100: #01518a,
	200: #01518a,
	300: #01518a,
	400: #01518a,
	500: #01518a,
	600: #01518a,
	700: #01518a,
	800: #01518a,
	900: #01518a,
	A100: #01518a,
	A200: #01518a,
	A400: #01518a,
	A700: #01518a,
	contrast: (
		50: white,
		100: white,
		200: white,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: white,
		A200: white,
		A400: white,
		A700: white,
	),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$splash-primary: mat.m2-define-palette($mat-custom);
$splash-accent: mat.m2-define-palette($mat-custom);

:root {
	--mdc-checkbox-selected-icon-color: #01518a;
}

// The warn palette is optional (defaults to red).
$splash-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$splash-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $splash-primary,
			accent: $splash-accent,
			warn: $splash-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($splash-theme);

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
}

.material-icons-outlined {
	font-variation-settings:
		'FILL' 0,
		'wght' 100,
		'GRAD' 0,
		'opsz' 48;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
}
